import { DynamicFulfillmentState } from '@/store/dynamicfulfillment/types';
import Vue from 'vue';

export class UserAuthorizationProvider extends Vue {

    constructor(private dfState: DynamicFulfillmentState) {
        super();        
    }

    public hasPermission(authorizedPermissions: string[]): boolean {
        if (!authorizedPermissions || authorizedPermissions.length === 0) {
            return true;
        }
        const permissions = this.dfState.userRolesPermissions?.permissions;
        return authorizedPermissions.filter((role: string) => permissions && permissions.includes(role)).length >= 1;
    }

    public getUserId(token: any): string {
        const claims = this.parseJwt(token); 
        const id = claims['sub'] ? claims['sub'] : [];
        return id;
    }

    private parseJwt(token: any): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    public async hasDefaultPermissions(token: any): Promise<boolean> {
        const claims = this.parseJwt(token); 
        return claims['scp'] ? claims['scp'].includes('cl.df.support') : false

    }
}

export const userAuthorizationProviderFactory = (dfState: DynamicFulfillmentState) => {
    return new UserAuthorizationProvider(dfState);
};
