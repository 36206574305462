import Vue from 'vue';
import { ActionContext, ActionTree } from 'vuex';
import {
  FulfillmentAuthorization,
  SourceSystemIdentifier,
  OfferSetQuery,
  FulfillmentTicketByAuthorizationQuery,
  FulfillmentTicket,
  FulfillmentTicketOrchestrationDetail,
  SourceSystemConfig,
  FulfillmentOptionConfig,
  FulfillmentOption,
  UpdateTicketStatusModel,
  IConfig,
  Product,
  PriceListConfig,
  PriceListItem,
  InsertPriceListItemsRequest,
  DynamicFulfillmentState,
  IPriceListConfig,
  IPriceListItem,
  UpgradePriceListItem,
  ProgramConfig,
  VendorConfig,
  InventoryState,
  FulfillmentAuthorizationSearchPageResult,
  VendorTicketStatusMapping,
  LocaleConfig,
  Vendor,
  LoanerProgramConfig,
  TicketAuthorizationAmountUpdateRequest,
  RoleConfig,
  UserConfig,
  EquipmentExclusionConfig,
  EquipmentExclusionItem,
  SearchByAuthIdQuery,
  VendorTicketStatusUpdateRequest,
  AncillaryServiceTypeConfig,
  ProgramFeesConfig,
  ServiceNetworkFulfillmentOptionTypeConfig,
  AccessRequest,
} from './types';
import * as apis from './constants';
import { RootState } from '../types';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { operationsApiProvider } from '@/common/operations.api';
import { userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import { ServiceCenterLocationConfig } from '../globalmodules/types';
import { locationManagementApiProvider } from '@/common/locationManagement.api';
import { DateTime } from 'luxon';
import { sortByProperty } from '@/common/functions.helpers';

const mapDescriptionItemPrimaryToShadow = (config: any) => {
  if (config && config.userInterfaceSettings) {
    config.userInterfaceSettings.descriptionItemsShadow = config.userInterfaceSettings.descriptionItems.map((item: any) => {
      return { itemText: item };
    });
  }
};

const mapDescriptionItemShadowToPrimary = (config: any) => {
  if (config && config.userInterfaceSettings && config.userInterfaceSettings.descriptionItemsShadow) {
    config.userInterfaceSettings.descriptionItems = config.userInterfaceSettings.descriptionItemsShadow.map((item: any) => {
      return item.itemText;
    });
  }
};

function filterConfigsByProgramId<T>(configs: T[], getProgramIds: (config: T) => string[], getProgramConfigIds: () => string[]): T[] {
  const programConfigIds = getProgramConfigIds();

  return configs.filter(config => {
    const programIds = getProgramIds(config);
    if (programIds.length === 0) {
      return true;
    }
    return programIds.some(id => programConfigIds.includes(id));
  });
}

export const actions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.INIT_USER](context): Promise<void> {
    if (!context.state.userClaims || !context.state.userClaims.preferred_username) {
      const claims = await Vue.prototype.$auth.getUser();
      const userAccessToken = await Vue.prototype.$auth.getAccessToken();
      const userAuthorizationProvider = userAuthorizationProviderFactory(context.state);
      const userId = userAuthorizationProvider.getUserId(userAccessToken);
      context.commit(apis.SET_USER_CLAIMS, claims);
      try {
        const userRolePermissions = await authorizationApiProvider.getUserRolesPermissions(userId);
        context.commit(apis.SET_USER_ROLES_PERMISSIONS, userRolePermissions);
      } catch (error) {
        throw Error(`Unable to retrieve UserConfig for user ${userId}.`);
      }
    }
  },

  async [apis.INIT_CONFIG](context): Promise<void> {
    if (context.state.initCompleted === false) {
      await context.dispatch(apis.INIT_USER);
      await context.dispatch(apis.FETCH_SOURCE_SYSTEM_CONFIGS);
      context.commit(apis.INIT_COMPLETED);
    }
  },

  async [apis.SEARCH_AUTH_BY_ID]({ state, commit }, request: SearchByAuthIdQuery): Promise<FulfillmentAuthorization> {
    let auth = state.authorizations[request.authorizationId] as FulfillmentAuthorization;
    if (!auth || request.forceFetch === true) {
      auth = await authorizationApiProvider.getAuthorization(request.authorizationId);
      commit(apis.SET_FULFILLMENT_AUTHORIZATION, auth);
    }
    return auth;
  },

  async [apis.SEARCH_AUTH_BY_SOURCE_IDENTIFIERS](context, sourceSystemIdentifier: SourceSystemIdentifier): Promise<FulfillmentAuthorization> {
    const auth = await authorizationApiProvider.getAuthorizationBySourceIdentifiers(sourceSystemIdentifier.sourceSystem, sourceSystemIdentifier.claimNumber);
    context.commit(apis.SET_FULFILLMENT_AUTHORIZATION, auth);
    return auth;
  },

  async [apis.SEARCH_AUTH_BY_FULFILLMENT_TICKET_ID](context, fulfillmentTicketId: string): Promise<FulfillmentAuthorization> {
    const auth = await authorizationApiProvider.getAuthorizationByTicketId(fulfillmentTicketId);
    context.commit(apis.SET_FULFILLMENT_AUTHORIZATION, auth);
    return auth;
  },

  async [apis.SEARCH_FULFILLMENT_TICKET_BY_AUTH](context, query: FulfillmentTicketByAuthorizationQuery) {
    const auth = (await context.dispatch(apis.SEARCH_AUTH_BY_ID, { authorizationId: query.authorizationId, forceFetch: false })) as FulfillmentAuthorization;
    return auth.fulfillmentTickets.find((t: FulfillmentTicket) => t.id === query.fulfillmentTicketId);
  },

  async [apis.SEARCH_AUTH_BY_PHONE_NUMBER](context, params: { count: number; phone: string; pagingToken: string }): Promise<FulfillmentAuthorizationSearchPageResult> {
    const auth = await authorizationApiProvider.getAuthorizationsByPhoneNumber(params.count, params.phone, params.pagingToken);
    return auth;
  },

  async [apis.SEARCH_AUTH_BY_KEY_VALUE](context, params: { count: number; collection: string; key: string; value: string; pagingToken: string }): Promise<FulfillmentAuthorizationSearchPageResult> {
    const auth = await authorizationApiProvider.getAuthorizationsByKeyValue(params.count, params.collection, params.key, params.value, params.pagingToken);
    return auth;
  },

  async [apis.SEARCH_FULFILLMENT_TICKET_BY_BOOKED_OFFER_CONFIRMATION_ID](context, bookedOfferConfirmationId: string): Promise<FulfillmentTicket[]> {
    return await authorizationApiProvider.getFulfillmentTicketsByBookedOfferConfirmationId(bookedOfferConfirmationId);
  },

  async [apis.FETCH_FULFILLMENT_TICKET_ORCHESTRATION](context, orchestrationId: string): Promise<FulfillmentTicketOrchestrationDetail> {
    return await authorizationApiProvider.getOrchestrationInfo(orchestrationId);
  },

  async [apis.RESTART_FULFILLMENT_TICKET_ORCHESTRATION](context, fulfillmentTicketId: string) {
    return await authorizationApiProvider.restartFulfillmentTicketOrchestration(fulfillmentTicketId);
  },

  async [apis.FETCH_OFFER_SET](context, query: OfferSetQuery) {
    return await authorizationApiProvider.getOfferSet(query.authorizationId, query.offerSetId);
  },

  async [apis.FETCH_SOURCE_SYSTEM_CONFIGS](context) {
    const configs = await authorizationApiProvider.getSourceSystemConfigs();
    const userAuthorizationProvider = userAuthorizationProviderFactory(context.state);
  
    const eligibleSourceSystems = configs.filter(sourceSystemConfig => 
      userAuthorizationProvider.hasPermission([`SourceSystem-${sourceSystemConfig.sourceSystem}`])
    );

    context.commit(apis.SET_SOURCE_SYSTEM_CONFIGS, eligibleSourceSystems);
    return context.state.sourceSystemConfigs;
  },

  async [apis.FETCH_SOURCE_SYSTEM_CONFIG](context, sourceSystem: string) {
    const config = context.state.sourceSystemConfigs.find(s=> s.id===sourceSystem);
    if (!config) {
      await context.dispatch(apis.FETCH_SOURCE_SYSTEM_CONFIGS);
    }
    return context.state.sourceSystemConfigs.find(s=> s.id===sourceSystem);
  },

  async [apis.SAVE_SOURCE_SYSTEM_CONFIG](context, config: SourceSystemConfig) {
    config = await authorizationApiProvider.updateSourceSystemConfig(config);
    context.commit(apis.SET_SOURCE_SYSTEM_CONFIG, config);
  },

  async [apis.FETCH_FULFILLMENT_OPTION_CONFIGS](context) {
    let fulfillmentOptionConfigs = context.state.fulfillmentOptionConfigs;
    if (fulfillmentOptionConfigs.length > 0) {
      return fulfillmentOptionConfigs;
    }
    // tslint:disable-next-line:max-line-length
    fulfillmentOptionConfigs = await authorizationApiProvider.getFulfillmentOptionConfigs();
    fulfillmentOptionConfigs.forEach((config) => {
      mapDescriptionItemPrimaryToShadow(config);
    });
    context.commit(apis.SET_FULFILLMENT_OPTION_CONFIGS, fulfillmentOptionConfigs);
    return fulfillmentOptionConfigs;
  },

  async [apis.FETCH_PROGRAM_CONFIGS](context): Promise<ProgramConfig[]> {
    if (!context.state.sourceSystemConfigs || context.state.sourceSystemConfigs.length == 0) {
      await context.dispatch(apis.FETCH_SOURCE_SYSTEM_CONFIGS, true);
    }
  
    const eligibleSourceSystems = context.state.sourceSystemConfigs.map(sourceSystemConfig => sourceSystemConfig.sourceSystem);

    const configs = await authorizationApiProvider.getProgramConfigs();
  
    const eligibleProgramConfigs = configs.filter(programConfig => 
      eligibleSourceSystems.includes(programConfig.externalSystem)
    );
  
    context.commit(apis.SET_PROGRAM_CONFIGS, eligibleProgramConfigs);
    return eligibleProgramConfigs;
  },

  async [apis.FETCH_SOURCE_SYSTEM_PROGRAM_CONFIGS](context, request: { sourceSystem: string }): Promise<ProgramConfig[]> {
    let programConfigs: ProgramConfig[] = [];
  
    if (!context.state.programConfigs || context.state.programConfigs.length == 0) {
      await context.dispatch(apis.FETCH_PROGRAM_CONFIGS, true);
    }

    programConfigs = context.state.programConfigs.filter((c) => c.externalSystem.toLowerCase() === request.sourceSystem.toLowerCase());
  
    return programConfigs.length > 0 ? programConfigs : [];
  },

  async [apis.FETCH_PROGRAM_BY_ID_CONFIG](context, id: string) {
    if (!context.state.programConfigs || context.state.programConfigs.length == 0) {
      await context.dispatch(apis.FETCH_PROGRAM_CONFIGS);
    }
    return context.state.programConfigs.find((c) => c.id === id);
  },

  async [apis.PROGRAM_CONFIG_SAVE](context, config: ProgramConfig) {
    if ((config.id === null || config.id === undefined) && context.state.programConfigs.find((c) => c.id === config.id)) {
      throw Error(`Program with Id ${config.id} already exists.`);
    }
    await authorizationApiProvider.updateProgramConfig(config);
    context.commit(apis.RESET_PROGRAM_CONFIGS);
    await context.dispatch(apis.FETCH_PROGRAM_CONFIGS);
  },

  async [apis.DELETE_PROGRAM](context, params: { config: ProgramConfig, hardDelete: boolean }) {
    if (!context.state.programConfigs || context.state.programConfigs.length === 0) {
      await context.dispatch(apis.FETCH_PROGRAM_CONFIGS, true);
    }

    await authorizationApiProvider.deleteProgram(params.config, params.hardDelete).then(async () => {
      await authorizationApiProvider.clearCache();
      context.commit(apis.DELETE_PROGRAM, params.config);
    });
  },

  async [apis.FETCH_VENDOR_CONFIGS](context) {
    let vendorConfigs = context.state.vendorConfigs;
    if (vendorConfigs && vendorConfigs.length > 0) {
      return vendorConfigs;
    }
    vendorConfigs = await authorizationApiProvider.getVendorConfigs();
    vendorConfigs.forEach((config) => {
      mapDescriptionItemPrimaryToShadow(config);
      if (config.serviceProviderConfigs === undefined) {
        config.serviceProviderConfigs = [];
      } else {
        config.serviceProviderConfigs.forEach((element) => {
          if (element.loanerProgramConfig === null) {
            element.loanerProgramConfig = {} as LoanerProgramConfig;
          }
        });
      }
    });
    context.commit(apis.SET_VENDOR_CONFIGS, vendorConfigs);
    return vendorConfigs;
  },

  async [apis.FETCH_VENDOR_CONFIG](context, id: string) {
    if (!context.state.vendorConfigs || context.state.vendorConfigs.length == 0) {
      await context.dispatch(apis.FETCH_VENDOR_CONFIGS, true);
    }
    return context.state.vendorConfigs.find((c) => c.id === id);
  },

  async [apis.VENDOR_CONFIG_SAVE](context, config: VendorConfig) {
    mapDescriptionItemShadowToPrimary(config);
    if ((config.id === null || config.id === undefined) && context.state.vendorConfigs.find((c) => c.id === config.id)) {
      throw Error(`Vendor with Id ${config.id} already exists.`);
    }
    await authorizationApiProvider.updateVendorConfig(config);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_VENDOR_CONFIGS);
    await context.dispatch(apis.FETCH_VENDOR_CONFIGS);
  },

  async [apis.VENDOR_CONFIG_DELETE](context, params: { config: any, hardDelete: boolean }) {
    await authorizationApiProvider.removeVendorConfig(params.config);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_VENDOR_CONFIGS);
    await context.dispatch(apis.FETCH_VENDOR_CONFIGS, true);
  },

  async [apis.RESET_VENDOR_CONFIGS](context) {
    context.commit(apis.RESET_VENDOR_CONFIGS);
  },

  async [apis.FETCH_FULFILLMENT_OPTION_CONFIG](context, id: string) {
    if (!context.state.fulfillmentOptionConfigs || context.state.fulfillmentOptionConfigs.length == 0) {
      await context.dispatch(apis.FETCH_FULFILLMENT_OPTION_CONFIGS, true);
    }
    return context.state.fulfillmentOptionConfigs.find((c) => c.id === id);
  },

  async [apis.FULFILLMENT_OPTION_CONFIG_SAVE](context, config: FulfillmentOptionConfig) {
    mapDescriptionItemShadowToPrimary(config);
    if (config && config.vendors) {
      config.vendors.forEach((v: Vendor) => {
        if (v.operationsStrategy?.name == '' || v.operationsStrategy?.name == null) {
          v.operationsStrategy = null;
        }
      });
    }

    await authorizationApiProvider.updateFulfillmentOptionConfig(config);
    context.commit(apis.RESET_FULFILLMENT_OPTION_CONFIGS);
    await context.dispatch(apis.FETCH_FULFILLMENT_OPTION_CONFIGS);
  },

  async [apis.FETCH_PRICE_LIST_CONFIGS](context, request:{route: string, forceFetch: boolean}) {
    switch (request.route) {
      case 'upgradepricelists': {
        if (context.state.upgradePriceListConfigs != null && !request.forceFetch) {
          return;
        }
        break;
      }
      default: {
        request.route = 'pricelists';
        if (context.state.priceListConfigs != null && !request.forceFetch) {
          return;
        }
      }
    }
    let configs = await authorizationApiProvider.getPriceListConfigs<IPriceListItem>(request.route);
    const dictionary = {} as { [key: string]: IPriceListConfig<IPriceListItem> };
    
    if (!context.state.programConfigs || context.state.programConfigs.length == 0) {
      await context.dispatch(apis.FETCH_PROGRAM_CONFIGS, true);
    }

    configs = filterConfigsByProgramId(
      configs,
      config => config.programIds,
      () => context.state.programConfigs.map(config => config.id)
    );

    configs.forEach((c) => {
      dictionary[c.id] = c;
    });
    let routeSetting = request.route;
    context.commit(apis.SET_PRICE_LIST_CONFIGS, { dictionary, configs, routeSetting });
  },

  async [apis.CLEAR_PRICE_LIST_CONFIGS](context, route: string) {
    context.commit(apis.CLEAR_PRICE_LIST_CONFIGS, { route });
  },

  async [apis.FETCH_PRICE_LIST_CONFIG](context, request: { id: string; route: string }) {
    switch (request.route) {
      case 'upgradepricelists': {
        if (context.state.upgradePriceListConfigs == null || context.state.upgradePriceListConfigs[request.id] == null) {
          await context.dispatch(apis.FETCH_PRICE_LIST_CONFIGS, {route: request.route, forceFetch: false});
        }
        return context.state.upgradePriceListConfigs[request.id];
      }
      default: {
        if (context.state.priceListConfigs == null || context.state.priceListConfigs[request.id] == null) {
          await context.dispatch(apis.FETCH_PRICE_LIST_CONFIGS, {route: request.route, forceFetch: false});
        }
        return context.state.priceListConfigs[request.id];
      }
    }
  },

  async [apis.FETCH_PRICE_LIST_CONFIG_ITEMS](context, request: { id: string; route: string }) {
    switch (request.route) {
      case 'upgradepricelists': {
        if (context.state.upgradePriceListConfigs == null || context.state.upgradePriceListConfigs[request.id] == null) {
          const items = (await authorizationApiProvider.getPriceListConfig<IPriceListItem>(request.id, request.route)).items as UpgradePriceListItem[];
          context.state.upgradePriceListConfigs[request.id].items.push(...items);
        }
        return context.state.upgradePriceListConfigs[request.id];
      }
      default: {
        if (context.state.priceListConfigs == null || context.state.priceListConfigs[request.id] == null) {
          await context.dispatch(apis.FETCH_PRICE_LIST_CONFIGS, {route: request.route, forceFetch: false});
        }

        if (context.state.priceListConfigs[request.id]?.items.length === 0) {
          const items = (await authorizationApiProvider.getPriceListConfig<IPriceListItem>(request.id, request.route)).items as PriceListItem[];
          context.state.priceListConfigs[request.id].items = items;
        }
        return context.state.priceListConfigs[request.id];
      }
    }
  },

  async [apis.INSERT_PRICE_LIST_ITEMS](context, request: { payload: any; configRoute: string; itemRoute: string }) {
    return new Promise<void>(async (resolve, reject) => {
      await authorizationApiProvider
        .insertPriceListItems<IPriceListItem>(request)
        .then(async () => {
          context.commit(apis.CLEAR_PRICE_LIST_CONFIGS, request.configRoute);
          resolve();
        })
        .catch((error) => reject(error));
    });
  },

  async [apis.INSERT_PRICE_LIST_CONFIG](context, request: { payload: any; route: string }) {
    const config = await authorizationApiProvider.insertPriceListConfig<IPriceListItem>(request.payload, request.route);
    switch (request.route) {
      case 'upgradepricelists': {
        context.commit(apis.SET_UPGRADE_PRICE_LIST_CONFIG, config);
        break;
      }
      default: {
        context.commit(apis.SET_PRICE_LIST_CONFIG, config);
      }
    }
    return config;
  },

  async [apis.UPDATE_PRICE_LIST_CONFIG](context, request: { config: IPriceListConfig<IPriceListItem>; apiRoute: string }) {
    await authorizationApiProvider.updatePriceListConfig<IPriceListItem>(request.config, request.apiRoute);
    context.commit(apis.CLEAR_PRICE_LIST_CONFIGS, request.apiRoute);
  },

  async [apis.DELETE_PRICE_LIST_CONFIG](context, config: IPriceListConfig<IPriceListItem>) {
    if (!context.state.fullPriceListConfigs || context.state.fullPriceListConfigs.length === 0) {
      await context.dispatch(apis.FETCH_PRICE_LIST_CONFIGS, {route: 'pricelists', forceFetch: false});
    }

    await authorizationApiProvider.deletePriceListConfig(config).then(async () => {
      await authorizationApiProvider.clearCache();
      context.commit(apis.DELETE_PRICE_LIST_CONFIG, config);
    });
  },

  async [apis.CANCEL_VENDOR_OFFER](context, ticket: FulfillmentTicket) {
    await authorizationApiProvider.cancelVendorOffer(ticket);
  },

  async [apis.FETCH_SOURCE_SYSTEM_CLAIM](context, sourceSystemIdentifier: SourceSystemIdentifier) {
    return await authorizationApiProvider.getClaimData(sourceSystemIdentifier);
  },

  async [apis.FETCH_VENDOR_STATUS_EVENTS](context, correlationId: string) {
    return await authorizationApiProvider.getVendorStatusEvents(correlationId, "VendorTicketStatusUpdatedEvent");
  },

  async [apis.FETCH_ORDER_STATUS_EVENTS](context, correlationId: string) {
    return await authorizationApiProvider.getVendorStatusEvents(correlationId, "VendorOrderStatusUpdatedEvent");
  },

  async [apis.UPDATE_TICKET_STATUS](context, request: UpdateTicketStatusModel) {
    await authorizationApiProvider.updateTicketStatus(request);
    context.commit(apis.RESET_AUTH, request.authorizationId);
  },

  async [apis.OVERRIDE_TICKET_STATUS](context, request: UpdateTicketStatusModel) {
    await authorizationApiProvider.overrideTicketStatus(request);
    context.commit(apis.RESET_AUTH, request.authorizationId);
  },

  async [apis.SEND_VENDOR_TICKET_STATUS](context, request: VendorTicketStatusUpdateRequest) {
    await authorizationApiProvider.sendVendorTicketStatus(request);
  },

  async [apis.UPDATE_TICKET_AUTHORIZATION_AMOUNT](context, request: TicketAuthorizationAmountUpdateRequest) {
    await authorizationApiProvider.updateTicketAuthorizationAmountAsync(request);
    context.commit(apis.RESET_AUTH, request.authorizationId);
  },

  async [apis.PRODUCTS_FETCH](context, request: { sourceSystem: string }): Promise<Product[]> {
    return (await authorizationApiProvider.getProducts(request.sourceSystem)) as Product[];
  },

  async [apis.PRODUCTS_BY_ID_FETCH](context, request: { sourceSystem: string; id: string }): Promise<Product> {
    return (await authorizationApiProvider.getProduct(request.sourceSystem, request.id)) as Product;
  },

  async [apis.PRODUCTS_SAVE](context, product: Product): Promise<Product> {
    let savedProduct = null;
    if (product.id) {
      savedProduct = await authorizationApiProvider.editProduct(product);
    } else {
      savedProduct = await authorizationApiProvider.createProduct(product);
    }
    context.dispatch(apis.CLEAR_CACHE);
    return savedProduct;
  },

  async [apis.PRODUCTS_DELETE](context, params: { config: Product, hardDelete: boolean }): Promise<void> {
    await authorizationApiProvider.deleteProduct(params.config, params.hardDelete).then(async () => {
      await authorizationApiProvider.clearCache();
      await context.dispatch(apis.PRODUCTS_FETCH, { sourceSystem: params.config.sourceSystem });
    });
  },

  async [apis.STATUS_MAPPING_FETCH](context, param:{apiRoute: string}) {
    let statusMappings = await authorizationApiProvider.getStatusMapping(param.apiRoute);
    switch(param.apiRoute) {
      case 'vendorticketstatus':
        statusMappings.map(s=> s.documentType = 'VendorTicketStatusMapping');
        break;
      case 'orderstatus':
        statusMappings.map(s=> s.documentType = 'OrderStatusMapping');
        break;
    }
    context.commit(apis.SET_STATUS_MAPPINGS, { apiRoute: param.apiRoute, statusMappings: statusMappings});
    return statusMappings;
  },

  async [apis.FETCH_STATUS_MAPPING_BY_ID](context, params: { apiRoute: string, id: string}) {
    switch(params.apiRoute) {
      case 'vendorticketstatus':
        if (!context.state.vendorTicketStatusMappings || context.state.vendorTicketStatusMappings.length == 0) {
          await context.dispatch(apis.STATUS_MAPPING_FETCH, params);
        }
        return context.state.vendorTicketStatusMappings.find((c) => c.id === params.id);
      case 'orderstatus':
        if (!context.state.orderStatusMappings || context.state.orderStatusMappings.length == 0) {
          await context.dispatch(apis.STATUS_MAPPING_FETCH, params);
        }
        return context.state.orderStatusMappings.find((c) => c.id === params.id);
      default:
        return [];
    }    
  },

  async [apis.SAVE_STATUS_MAPPING](context, params:{apiRoute: string, config: any}) {
    await authorizationApiProvider.updateStatusMapping(params.apiRoute, params.config);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_STATUS_MAPPINGS);
    await context.dispatch(apis.STATUS_MAPPING_FETCH, params);
  },

  async [apis.CREATE_STATUS_MAPPING](context, params: { apiRoute: string, config: any}) {
    switch(params.apiRoute) {
      case 'vendorticketstatus':
        if (params.config.eTag == null && context.state.vendorTicketStatusMappings.find((c) => c.id === params.config.id)) {
          throw Error(`Status Mapping with Id ${params.config.id} already exists.`);
        }
        break;
      case 'orderstatus':
        if (params.config.eTag == null && context.state.orderStatusMappings.find((c) => c.id === params.config.id)) {
          throw Error(`Status Mapping with Id ${params.config.id} already exists.`);
        }
        break;
    }
    
    await authorizationApiProvider.createStatusMapping(params.apiRoute,params.config);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_STATUS_MAPPINGS, params.apiRoute);
    await context.dispatch(apis.STATUS_MAPPING_FETCH, params);
  },

  async [apis.DELETE_STATUS_MAPPING](context, param: { apiRoute: string, config: any, hardDelete: boolean }) {
    await authorizationApiProvider.deleteStatusMapping(param.apiRoute, param.config, param.hardDelete);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_STATUS_MAPPINGS);
    await context.dispatch(apis.STATUS_MAPPING_FETCH, param);
  },

  async [apis.STATUS_MAPPING_BY_VENDOR_FULFILLMENTOPTION_FETCH](context: any, request: {apiRoute:string, fulfillmentOption: number; vendor: string }) {    
    return context.dispatch(apis.STATUS_MAPPING_FETCH, {apiRoute: request.apiRoute}).then( () => {
      switch(request.apiRoute) {
        case 'vendorticketstatus':
          return context.state.vendorTicketStatusMappings.filter((c: any) => c.fulfillmentOption === request.fulfillmentOption && c.vendor === request.vendor);    
        case 'orderstatus':
          return context.state.orderStatusMappings.filter((c: any) => c.fulfillmentOption === request.fulfillmentOption && c.vendor === request.vendor);
      }    
    });    
  },

  async [apis.FETCH_AUTH_TRACES](context, authorizationId: string) {
    return await authorizationApiProvider.getDiagnosticTraces(authorizationId);
  },

  async [apis.FETCH_AUTH_REQUESTS](context, authorizationId: string) {
    return await authorizationApiProvider.getDiagnosticRequests(authorizationId);
  },

  async [apis.FETCH_COMMUNICATIONS](context, authorizationId: string) {
    return await authorizationApiProvider.getCommunicationsByAuthId(authorizationId);
  },

  async [apis.FETCH_ALL_SUCCESSFUL_AUTH_REQUESTS]() {
    return await authorizationApiProvider.getAllSuccessFulAuthDiagnosticRequests();
  },

  async [apis.FETCH_RECENT_AUTHORIZATIONS](context, count: number) {
    return await authorizationApiProvider.getRecentAuthorizations(count);
  },

  async [apis.FETCH_AI_EXCEPTIONS](context, count: number) {
    return await authorizationApiProvider.getDiagnosticExceptions(count);
  },

  async [apis.CLEAR_CACHE](context) {
    return await authorizationApiProvider.clearCache();
  },

  async [apis.CLEAR_CACHE_BY_KEY](context, key: string) {
    return await authorizationApiProvider.clearCacheByKey(key);
  },

  async [apis.FETCH_INVENTORY_STATES](context) {
    let inventoryStatePage = context.state.inventoryStatePage;
    if (inventoryStatePage && inventoryStatePage.pagingToken) {
      const inventoryStatePageResponse = await authorizationApiProvider.getInventoryStates(inventoryStatePage.pagingToken);
      inventoryStatePage.inventoryStates.push(...inventoryStatePageResponse.inventoryStates);
      inventoryStatePage.pagingToken = inventoryStatePageResponse.pagingToken;
    } else if (inventoryStatePage.inventoryStates && inventoryStatePage.inventoryStates.length > 0) {
      return inventoryStatePage.inventoryStates; // reached end of the page. Do not make the api call.
    } else {
      inventoryStatePage = await authorizationApiProvider.getInventoryStates(null);
    }
    context.commit(apis.SET_INVENTORY_STATES_PAGING_DATA, inventoryStatePage);
    return inventoryStatePage.inventoryStates;
  },

  async [apis.REFRESH_INVENTORY_STATES](context) {
    context.commit(apis.RESET_INVENTORY_STATES);
  },

  async [apis.FETCH_INVENTORY_STATE](context, id: string) {
    let inventoryState = context.state.inventoryStatePage?.inventoryStates?.find((c) => c.id === id);
    if (!inventoryState) {
      inventoryState = await authorizationApiProvider.getInventoryState(id);
    }
    return inventoryState;
  },

  async [apis.SAVE_INVENTORY_STATE](context, inventoryState: InventoryState) {
    await authorizationApiProvider.upsertInventoryState(inventoryState).then(() => context.commit(apis.ADD_INVENTORY_STATE, inventoryState));
  },

  async [apis.UPDATE_INVENTORY_STATE](context, inventoryState: InventoryState) {
    await authorizationApiProvider.upsertInventoryState(inventoryState).then(() => context.commit(apis.UPDATE_INVENTORY_STATE, inventoryState));
  },

  async [apis.SET_INVENTORY_STATE](context, inventoryState: InventoryState) {
    context.commit(apis.SET_INVENTORY_STATE, inventoryState);
  },

  async [apis.DELETE_INVENTORY_STATE](context, inventory: InventoryState) {
    await authorizationApiProvider.deleteInventoryState(inventory.id).then(() => context.commit(apis.DELETE_INVENTORY_STATE, inventory.id));
  },

  async [apis.FETCH_INVENTORY_PARTS_MAPPING_STATE](context) {
    return await authorizationApiProvider.getInventoryPartsStatesMapping();
  },

  async [apis.FETCH_JUSTINTIME_INVENTORY_PARTS_MAPPING_STATE](context) {
    return await authorizationApiProvider.getJustInTimeInventoryPartsStatesMapping();
  },

  async [apis.GET_FULFILLMENTAUTHORIZATION_FILTER_LOGS](context, id: string) {
    let filterLog = context.state.fulfillmentAuthFilterLogs.find((l) => l.id === id);
    if (filterLog) {
      return filterLog;
    } else {
      await authorizationApiProvider
        .getFulfillmentAuthorizationFilterLogs(id)
        .then((response) => {
          filterLog = response;
          context.commit(apis.SET_FULFILLMENTAUTHORIZATION_FILTER_LOGS, filterLog);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            filterLog = null;
          } else {
            return error;
          }
        });
    }
    return filterLog;
  },

  async [apis.FETCH_APPCONFIG_BY_KEY](context, key: string) {
    return await authorizationApiProvider.getAppConfigByKey(key);
  },

  async [apis.CREATE_APPCONFIG](context, request: { key: string; value: string }) {
    return await authorizationApiProvider.createAppConfig(request);
  },

  async [apis.UPDATE_APPCONFIG](context, request: { key: string; value: string }) {
    return await authorizationApiProvider.updateAppConfig(request);
  },

  async [apis.DELETE_APPCONFIG](context, key: string) {
    return await authorizationApiProvider.deleteAppConfig(key);
  },

  async [apis.GET_SVS_ORDER_BY_ID](context, id: string) {
    return await authorizationApiProvider.getSvsOrderById(id);
  },

  async [apis.SERVICECENTRAL_GET_CLAIM](context, id: string) {
    return await authorizationApiProvider.getServiceCentralClaimById(id);
  },

  async [apis.FETCH_LOCALE_CONFIGS](context) {
    let localeConfigs = await authorizationApiProvider.getLocaleConfigs();
    context.commit(apis.SET_LOCALE_CONFIGS, localeConfigs);
    return localeConfigs;
  },

  async [apis.FETCH_LOCALE_CONFIG](context, id: string) {
    if (!context.state.localeConfigs || context.state.localeConfigs.length == 0) {
      await context.dispatch(apis.FETCH_LOCALE_CONFIGS);
    }
    return context.state.localeConfigs.find((c) => c.id === id);
  },

  async [apis.UPDATE_LOCALE_CONFIG](context, localeConfig: LocaleConfig) {
    await authorizationApiProvider.updateLocaleConfig(localeConfig);
    context.commit(apis.RESET_LOCALE_CONFIGS);
    await context.dispatch(apis.FETCH_LOCALE_CONFIGS);
  },

  async [apis.CREATE_LOCALE_CONFIG](context, localeConfig: LocaleConfig) {
    if (localeConfig._etag == null && context.state.localeConfigs.find((c) => c.id === localeConfig.id)) {
      throw Error(`LocaleConfig with Id ${localeConfig.id} already exists.`);
    }
    await authorizationApiProvider.createLocaleConfig(localeConfig);
    context.commit(apis.RESET_LOCALE_CONFIGS);
    await context.dispatch(apis.FETCH_LOCALE_CONFIGS);
  },

  async [apis.DELETE_LOCALE_CONFIG](context, id: string) {
    await authorizationApiProvider.deleteLocaleConfig(id);
    context.commit(apis.RESET_LOCALE_CONFIGS);
    await context.dispatch(apis.FETCH_LOCALE_CONFIGS);
  },

  async [apis.DELETE_SERVICECENTRAL_CACHE](context) {
    return await authorizationApiProvider.deleteServiceCentralCache();
  },

  async [apis.DELETE_SERVICEPOWER_SERVICECENTERS_CACHE](context) {
    return await authorizationApiProvider.deleteServicePowerServiceCentersCache();
  },

  async [apis.FETCH_SERVICECENTER_STATE](context, id: string) {
    // Fetch service location and format open/end times
    const serviceLocation = await locationManagementApiProvider.getServiceLocationById(id);
    serviceLocation.openHours.forEach((o) => {
      o.startTimeFormatted = DateTime.fromFormat(o.startTime, 't').toFormat('HH:mm');
      o.endTimeFormatted = DateTime.fromFormat(o.endTime, 't').toFormat('HH:mm');
    });
    context.commit(apis.SET_SERVICECENTER_LOCATION_STATE, serviceLocation);
    return serviceLocation;
  },

  async [apis.FETCH_FULFILLMENTTICKET_INVOICES](context, id: string) {
    let fulfillmentTicketInvoices = await authorizationApiProvider.fetchFulfillmentTicketInvoices(id);
    context.commit(apis.SET_FULFILLMENTTICKET_INVOICES, fulfillmentTicketInvoices);
    return fulfillmentTicketInvoices;
  },

  async [apis.FETCH_SOURCE_SYSTEM_CLAIM_DATA](context, authorizationId: string) {
    return await authorizationApiProvider.getSourceSystemClaimData(authorizationId);
  },

  async [apis.UPDATE_SOURCE_SYSTEM_CONFIG_PARTIAL](context, params: { id: string; configType: string; items: [] }): Promise<void> {
    await authorizationApiProvider.updateSourceSystemConfigPartial(params.id, params.configType, params.items);
    await authorizationApiProvider.clearCache();
    await context.dispatch(apis.FETCH_SOURCE_SYSTEM_CONFIGS);
  },

  async [apis.FETCH_USER_ROLES_PERMISSIONS](context, id: string) {
    let userRolesPermissions = context.state.userRolesPermissions;
    if (userRolesPermissions != undefined) {
      return userRolesPermissions;
    }
    userRolesPermissions = await authorizationApiProvider.getUserRolesPermissions(id);
    context.commit(apis.SET_USER_ROLES_PERMISSIONS, userRolesPermissions);
    return userRolesPermissions;
  },

  async [apis.FETCH_ROLES](context) {
    let roles = context.state.roles;
    if (!roles && roles.length === 0) {
      return roles;
    }
    roles = await authorizationApiProvider.getRoles();
    context.commit(apis.SET_ROLES, roles);
    return roles;
  },

  async [apis.FETCH_ROLE](context, id: string) {
    if (!context.state.roles || context.state.roles.length == 0) {
      await context.dispatch(apis.FETCH_ROLES, true);
    }
    return context.state.roles.find((c) => c.id === id);
  },

  async [apis.UPDATE_ROLE](context, config: RoleConfig) {
    await authorizationApiProvider.updateRole(config);
  },

  async [apis.CREATE_ROLE](context, config: RoleConfig) {
    if (config._etag === null && context.state.roles.find((c: any) => c.id === config.id)) {
      throw Error(`Role with Id ${config.id} already exists.`);
    }
    await authorizationApiProvider.createRole(config);
  },

  async [apis.DELETE_ROLE](context, config: RoleConfig) {
    if (context.state.userConfigs.length === 0) {
      await context.dispatch(apis.FETCH_USER_CONFIGS, true);
    }

    const userConfig = context.state.userConfigs.some((userConfig: UserConfig) => userConfig.roles.some((c: any) => c === config.id));
    if (userConfig) {
      throw Error(`${config.id}, is currently assigned to user(s). Remove the role from user(s) and try again.`);
    }

    authorizationApiProvider.deleteRole(config).then(() => {
      context.dispatch(apis.CLEAR_CACHE);
      context.commit(apis.DELETE_ROLE, config);
    });
  },

  async [apis.FETCH_PERMISSIONS](context) {
    let permissions = await authorizationApiProvider.getPermissions();
    context.commit(apis.SET_PERMISSIONS, permissions);
    return permissions;
  },

  async [apis.FETCH_USER_CONFIGS](context) {
    const userConfigs = await authorizationApiProvider.getUserConfigs();
    context.commit(apis.SET_USER_CONFIGS, userConfigs);
    return userConfigs;
  },

  async [apis.FETCH_USER_CONFIG](context, id: string) {
    const userConfig = context.state.userConfigs.find((config: UserConfig) => config.id === id);
    return userConfig ? userConfig : await authorizationApiProvider.getUserById(id);
  },

  async [apis.CREATE_USER_CONFIG](context, config: UserConfig) {
    await authorizationApiProvider.createUser(config);
  },

  async [apis.EDIT_USER_CONFIG](context, config: UserConfig) {
    await authorizationApiProvider.editUser(config);
  },

  async [apis.DELETE_USER_CONFIG](context, config: UserConfig) {
    authorizationApiProvider.deleteUser(config).then(() => {
      context.dispatch(apis.CLEAR_CACHE);
      context.commit(apis.DELETE_USER_CONFIG, config);
    });
  },

  async [apis.FETCH_EQUIPMENT_EXCLUSION_CONFIGS](context) {
    if (!context.state.programConfigs || context.state.programConfigs.length == 0) {
      await context.dispatch(apis.FETCH_PROGRAM_CONFIGS, true);
    }

    let equipmentExclusionConfigs = context.state.equipmentExclusionConfigs;
    if (equipmentExclusionConfigs.length > 0) {
      return equipmentExclusionConfigs;
    }
    equipmentExclusionConfigs = await authorizationApiProvider.getEquipmentExclusionConfigs();

    equipmentExclusionConfigs = filterConfigsByProgramId(
      equipmentExclusionConfigs,
      config => config.programIds,
      () => context.state.programConfigs.map(config => config.id)
    );

    context.commit(apis.SET_EQUIPMENT_EXCLUSION_CONFIGS, equipmentExclusionConfigs);
    return equipmentExclusionConfigs;
  },

  async [apis.FETCH_EQUIPMENT_EXCLUSION_ITEMS](context, request: { id: string }) {
    return await authorizationApiProvider.getEquipmentExclusionItems(request.id);
  },

  async [apis.FETCH_EQUIPMENT_EXCLUSION_CONFIG](context, id: string) {
    const equipmentExclusionListConfig = context.state.equipmentExclusionConfigs.find((config: EquipmentExclusionConfig) => config.id === id);
    return equipmentExclusionListConfig ? equipmentExclusionListConfig : await authorizationApiProvider.getEquipmentExclusionConfigById(id);
  },

  async [apis.CREATE_EQUIPMENT_EXCLUSION_CONFIG](context, config: EquipmentExclusionConfig) {
    await authorizationApiProvider.createEquipmentExclusionConfig(config);
    context.commit(apis.RESET_EQUIPMENT_EXCLUSION_CONFIGS);
    return await context.dispatch(apis.FETCH_EQUIPMENT_EXCLUSION_CONFIGS);
  },

  async [apis.EDIT_EQUIPMENT_EXCLUSION_CONFIG](context, config: EquipmentExclusionConfig) {
    await authorizationApiProvider.editEquipmentExclusionConfig(config);
  },

  async [apis.EQUIPMENT_EXCLUSION_CONFIG_DELETE](context, params: { config: EquipmentExclusionConfig, hardDelete: boolean }) {
    authorizationApiProvider.deleteEquipmentExclusionConfig(params.config).then(() => {
      context.dispatch(apis.CLEAR_CACHE);
      context.commit(apis.EQUIPMENT_EXCLUSION_CONFIG_DELETE, params.config);
    });
  },

  async [apis.CREATE_EQUIPMENT_EXCLUSION_ITEM](context, config: EquipmentExclusionItem) {
    const savedItem = await authorizationApiProvider.createEquipmentExclusionItem(config);
    context.dispatch(apis.CLEAR_CACHE);
    await context.dispatch(apis.FETCH_EQUIPMENT_EXCLUSION_ITEMS, config.equipmentExclusionConfigId);
    return savedItem;
  },

  async [apis.FETCH_EQUIPMENT_EXCLUSION_ITEM](context, request: { configId: string; id: string }) {
    return await authorizationApiProvider.getEquipmentExclusionItem(request.configId, request.id);
  },

  async [apis.EDIT_EQUIPMENT_EXCLUSION_ITEM](context, config: EquipmentExclusionItem) {
    await authorizationApiProvider.editEquipmentExclusionItem(config);
    context.dispatch(apis.CLEAR_CACHE);
    await context.dispatch(apis.FETCH_EQUIPMENT_EXCLUSION_ITEMS, config.id);
  },

  async [apis.EQUIPMENT_EXCLUSION_ITEM_DELETE](context, config: EquipmentExclusionItem) {
    await authorizationApiProvider.deleteEquipmentExclusionItem(config).then(() => {
      context.dispatch(apis.CLEAR_CACHE);
    });
  },

  async [apis.FETCH_CONFIG_HISTORY](context, request: { configType: string; id: string }) {
    const history = await authorizationApiProvider.getConfigHistory(request.configType, request.id);
    return history;
  },

  async [apis.FETCH_ANCILLARY_SERVICE_TYPE_CONFIGS](context) {
    let ancillaryServiceTypes = await authorizationApiProvider.getAncillaryServiceTypeConfigs();
    context.commit(apis.SET_ANCILLARY_SERVICE_TYPES, ancillaryServiceTypes);
    return ancillaryServiceTypes;
  },

  async [apis.FETCH_ANCILLARY_SERVICE_TYPE_CONFIG](context, id: string) {
    const ancillaryServiceTypeConfig = context.state.ancillaryServiceTypeConfigs.find((config: AncillaryServiceTypeConfig) => config.id === id);
    return ancillaryServiceTypeConfig ? ancillaryServiceTypeConfig : await authorizationApiProvider.getAncillaryServiceTypeById(id);
  },

  async [apis.CREATE_ANCILLARY_SERVICE_TYPE_CONFIG](context, ancillaryServiceTypeconfig: AncillaryServiceTypeConfig) {
    if (ancillaryServiceTypeconfig._etag == null && context.state.localeConfigs.find((c) => c.id === ancillaryServiceTypeconfig.id)) {
      throw Error(`AncillaryServiceTypeconfig with Id ${ancillaryServiceTypeconfig.id} already exists.`);
    }
    await authorizationApiProvider.createAncillaryServiceTypeConfig(ancillaryServiceTypeconfig);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_ANCILLARY_SERVICE_TYPES);
    await context.dispatch(apis.FETCH_ANCILLARY_SERVICE_TYPE_CONFIGS);
  },

  async [apis.UPDATE_ANCILLARY_SERVICE_TYPE_CONFIG](context, ancillaryServiceTypeconfig: AncillaryServiceTypeConfig) {
    await authorizationApiProvider.updateAncillaryServiceTypeConfig(ancillaryServiceTypeconfig);
    context.commit(apis.RESET_ANCILLARY_SERVICE_TYPES);
    await context.dispatch(apis.FETCH_ANCILLARY_SERVICE_TYPE_CONFIGS);
  },

  async [apis.FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIGS](context) {
    let ancillaryServiceTypes = await authorizationApiProvider.getServiceNetworkFulfillmentOptionTypeConfigs();
    context.commit(apis.SET_ANCILLARY_SERVICE_TYPES, ancillaryServiceTypes);
    return ancillaryServiceTypes;
  },

  async [apis.FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG](context, id: string) {
    const serviceNetworkFulfillmentOptionTypeConfig = context.state.serviceNetworkFulfillmentOptionTypeConfigs.find((config: ServiceNetworkFulfillmentOptionTypeConfig) => config.id === id);
    return serviceNetworkFulfillmentOptionTypeConfig ? serviceNetworkFulfillmentOptionTypeConfig : await authorizationApiProvider.getServiceNetworkFulfillmentOptionTypeById(id);
  },

  async [apis.CREATE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG](context, serviceNetworkFulfillmentOptionTypeConfig: ServiceNetworkFulfillmentOptionTypeConfig) {
    if (serviceNetworkFulfillmentOptionTypeConfig._etag == null && context.state.serviceNetworkFulfillmentOptionTypeConfigs.find((c) => c.id === serviceNetworkFulfillmentOptionTypeConfig.id)) {
      throw Error(`ServiceNetworkFulfillmentOptionTypeConfig with Id ${serviceNetworkFulfillmentOptionTypeConfig.id} already exists.`);
    }
    await authorizationApiProvider.createServiceNetworkFulfillmentOptionTypeConfig(serviceNetworkFulfillmentOptionTypeConfig);
    await authorizationApiProvider.clearCache();
    context.commit(apis.RESET_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES);
    await context.dispatch(apis.FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIGS);
  },

  async [apis.UPDATE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG](context, serviceNetworkFulfillmentOptionTypeConfig: ServiceNetworkFulfillmentOptionTypeConfig) {
    await authorizationApiProvider.updateServiceNetworkFulfillmentOptionTypeConfig(serviceNetworkFulfillmentOptionTypeConfig);
    context.commit(apis.RESET_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES);
    await context.dispatch(apis.FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIGS);
  },

  async [apis.FETCH_PROGRAM_FEES_CONFIGS](context) {  
    if (!context.state.programConfigs || context.state.programConfigs.length == 0) {
      await context.dispatch(apis.FETCH_PROGRAM_CONFIGS, true);
    }

    let programFeesConfigs = await authorizationApiProvider.getProgramFeesConfigs();
  
    programFeesConfigs = filterConfigsByProgramId(
      programFeesConfigs,
      config => [config.programId],
      () => context.state.programConfigs.map(config => config.id)
    );
  
    context.commit(apis.SET_PROGRAM_FEES_CONFIGS, programFeesConfigs);
    return programFeesConfigs;
  },

  async [apis.FETCH_PROGRAM_FEES_CONFIG](context, id: string) {
    const programFeesConfigs = context.state.programFeesConfigs.find((config: ProgramFeesConfig) => config.id === id);
    return programFeesConfigs ? programFeesConfigs : await authorizationApiProvider.getProgramFeesConfig(id);
  },

  async [apis.CREATE_PROGRAM_FEES_CONFIG](context, config: ProgramFeesConfig) {
    await authorizationApiProvider.createProgramFeesConfig(config);
  },

  async [apis.EDIT_PROGRAM_FEES_CONFIG](context, config: ProgramFeesConfig) {
    await authorizationApiProvider.editProgramFeesConfig(config);
  },

  async [apis.DELETE_PROGRAM_FEES_CONFIG](context, config: ProgramFeesConfig) {
    authorizationApiProvider.deleteProgramFeesConfig(config).then(() => {
      context.dispatch(apis.CLEAR_CACHE);
      context.commit(apis.DELETE_PROGRAM_FEES_CONFIG, config);
    });
  },  

  async [apis.FETCH_FEE_TYPES](context) {
    const feeTypes = await authorizationApiProvider.getFeeTypes();
    return feeTypes;
  },

  async [apis.FETCH_COUNTRY_CONFIGS](context) {
    const configs = await authorizationApiProvider.getCountryConfigs();
    context.commit(apis.SET_COUNTRY_CONFIGS, configs);
    return context.state.countryConfigs;
  },

  async [apis.SEND_ACCESS_REQUEST](context, accessRequest: AccessRequest) {
     await authorizationApiProvider.sendAccessRequest(accessRequest);
  },
};
