
import { Component } from 'vue-property-decorator';
import RemovedConfigIndexComponent from '@/components/configuration/RemovedConfigIndexComponent.vue';
import { Product, RoutingAction, } from '@/store/dynamicfulfillment/types';
import { PRODUCTS_DELETE } from '@/store/constants';
import ProductsIndex from './ProductsIndex.vue';
import { authorizationApiProvider } from '@/common/df.authorization.api';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { RemovedConfigIndexComponent } })
export default class RemovedProductsIndex extends ProductsIndex {
  private configItems: Product[] = null;

  async mounted() {
    await authorizationApiProvider.getProducts(this.getSourceSystem(), true)
        .then((configs) => this.configItems = configs);
  }

  get removedRouteActions() {
    return [
     { name: RoutingAction.Delete, action: PRODUCTS_DELETE, requiredPermission: 'Configuration-Programs-Delete', params: { hardDelete: true } },
    ];
  }
}
