
import { Component } from 'vue-property-decorator';
import RemovedConfigIndexComponent from '@/components/configuration/RemovedConfigIndexComponent.vue';
import VendorConfigIndex from '@/views/configuration/Vendors/VendorConfigIndex.vue';
import { VENDOR_CONFIG_DELETE } from '@/store/constants';
import { RoutingAction, VendorConfig } from '@/store/dynamicfulfillment/types';
import { authorizationApiProvider } from '@/common/df.authorization.api';
const namespace: string = 'dynamicfulfillmentmodule';
@Component({ components: { RemovedConfigIndexComponent } })
export default class RemovedVendorConfigIndex extends VendorConfigIndex {
  private configItems: VendorConfig[] = null;

  async mounted() {
    await authorizationApiProvider.getVendorConfigs(true)
        .then((configs) => this.configItems = configs);
  }

  get removedRouteActions() {
    return [
     { name: RoutingAction.Delete, action: VENDOR_CONFIG_DELETE, requiredPermission: 'Configuration-Vendors-Delete',  params: { hardDelete: true }},
    ];
  }
}
