import Vue from 'vue';
import Router, { Route, RouteConfig, NavigationGuard } from 'vue-router';
import Auth from '@okta/okta-vue';
import Search from '@/views/Search.vue';
import Home from '@/views/Home.vue';
import FulfillmentAuthorizationParent from '@/views/FulfillmentAuthorizationParent.vue';
import FulfillmentAuthorizationDetail from '@/views/FulfillmentAuthorizationDetail.vue';
import FulfillmentTicketDetail from '@/views/FulfillmentTicketDetail.vue';
import OfferSetDetail from '@/views/OfferSetDetail.vue';
import ConfigRoutes from '@/router/config.routes';
import store from '@/store';
import { INIT_CONFIG, INIT_USER } from '@/store/constants';
import ImplicitCallback from '@/components/auth/ImplicitCallback.vue';
import ClientMigrationMatrixUpload from '@/views/MigrationMatrix/ClientMigrationMatrixUpload.vue';
import AssurantMigrationMatrixUpload from '@/views/MigrationMatrix/AssurantMigrationMatrixUpload.vue';
import MigrationMatrixReportDownload from '@/views/MigrationMatrix/MigrationMatrixReportDownload.vue';
import BenefitCheck from '@/views/BenefitCheck.vue';
import LegacyClaimIndex from '@/views/legacy/LegacyClaimIndex.vue';
import LegacyClaimDetails from '@/views/legacy/LegacyClaimDetails.vue';
import ServiceCenterLocationAdd from '@/views/serviceCenters/ServiceCenterLocationAdd.vue';
import { userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import ViewServiceCenterLocations from '@/views/serviceCenters/ViewServiceCenterLocations.vue';
import ServiceCenterLocationEdit from '@/views/serviceCenters/ServiceCenterLocationEdit.vue';
import ServiceCenterLocationUpload from '@/views/serviceCenters/ServiceCenterLocationUpload.vue';
import ServiceNetworkIndex from '@/views/serviceCenters/ServiceNetworkIndex.vue';
import ServiceNetworkCreate from '@/views/serviceCenters/ServiceNetworkCreate.vue';
import ServiceNetworkEdit from '@/views/serviceCenters/ServiceNetworkEdit.vue';
import MigrationMatrixIndex from '@/views/MigrationMatrix/MigrationMatrixIndex.vue';
import InventoryStatesRoutes from '@/router/inventory.routes';
import AccessDenied from '@/views/AccessDenied.vue';
import VendorRoutes from '@/router/vendors.routes';
import LandingPage from '@/views/LandingPage.vue';
import Unauthorized from '@/views/Unauthorized.vue';
import ServiceManufacturersIndex from '@/views/serviceCenters/ServiceManufacturersIndex.vue';
import ServiceManufacturersCreate from '@/views/serviceCenters/ServiceManufacturersCreate.vue';
import ServiceManufacturersEdit from '@/views/serviceCenters/ServiceManufacturersEdit.vue';
import ServiceManufacturersUpload from '@/views/serviceCenters/ServiceManufacturerUpload.vue';
import { authorizationApiProvider } from '@/common/df.authorization.api';
import { DynamicFulfillmentState } from '@/store/dynamicfulfillment/types';
import OrderDetail from '@/views/FulfillmentAuthorization/OrderDetail.vue';

Vue.use(Router);

const tokenStorageKey = `okta-token-storage-df-support-ui-${process.env.VUE_APP_ENVIRONMENT}`;
const regionEnvironment = `${process.env.VUE_APP_REGION}-${process.env.VUE_APP_ENVIRONMENT}`;
const config = {
  clientId: process.env.VUE_APP_OAUTH2_CLIENT_ID,
  issuer: process.env.VUE_APP_OAUTH2_ISSUER,
  redirectUri: window.location.origin + '/implicit/callback',
  scopes: ['openid', 'profile', 'email', 'cl.df.support', 'offline_access'],
  pkce: true,
  preferLocalStorage: false,
  tokenManager: {
    storageKey: tokenStorageKey,
    storage: 'sessionStorage',
  },
};

Vue.use(Auth, { ...config });

const routes = [
  {
    path: '/implicit/callback',
    name: 'Redirecting...',
    component: ImplicitCallback,
  },
  {
    path: '/denied',
    name: 'AccessDenied',
    component: AccessDenied,
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
  },
  {
    path: '/welcome',
    name: 'LandingPage',
    component: LandingPage,
    beforeEnter: async (to: any, from: any, next: any) => {
      await store.dispatch(INIT_CONFIG);
      return next();
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home',
      requiresAuth: true,
      authorizedPermissions: ['Operations-FulfillmentSearch-View'],
    },
    beforeEnter: async (to: any, from: any, next: any) => {
      await store.dispatch(INIT_CONFIG);
      return next();
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      title: 'Search',
      requiresAuth: true,
      authorizedPermissions: ['Operations-FulfillmentSearch-View'],
    },
  },
  {
    path: '/migrationMatrixIndex',
    name: 'MigrationMatrix-Index',
    component: MigrationMatrixIndex,
    meta: {
      title: 'View Migration Matrices',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-MigrationMatrix-View'],
    },
  },
  {
    path: '/clientMigrationMatrixUpload',
    name: 'ClientMigrationMatrix-Upload',
    component: ClientMigrationMatrixUpload,
    meta: {
      title: 'Upload Client Matrix',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-MigrationMatrix-Upload'],
    },
  },
  {
    path: '/assurantMigrationMatrixUpload',
    name: 'AssurantMigrationMatrix-Upload',
    component: AssurantMigrationMatrixUpload,
    meta: {
      title: 'Upload Assurant Matrix',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-MigrationMatrix-Upload'],
    },
  },
  {
    path: '/migrationMatrixDownload',
    name: 'MigrationMatrix-Download',
    component: MigrationMatrixReportDownload,
    meta: {
      title: 'Migration Matrix Snapshot',
      requiresAuth: true,
      authorizedPermissions: ['Configuration-MigrationMatrix-Snapshot'],
    },
  },
  {
    path: '/benefitCheck',
    name: 'BenefitCheck',
    component: BenefitCheck,
    meta: {
      title: 'Benefit Check',
      requiresAuth: true,
      authorizedPermissions: ['Operations-BenefitCheck-View'],
    },
  },
  {
    path: '/legacyClaimDetails',
    name: 'LegacyClaim-Details',
    props: true,
    component: LegacyClaimDetails,
    meta: {
      title: 'Legacy Claim Details',
      requiresAuth: true,
      authorizedPermissions: ['Operations-FulfillmentSearch-View'],
    },
  },
  {
    path: '/serviceCenterLocationAdd',
    name: 'ServiceCenterLocation-Add',
    component: ServiceCenterLocationAdd,
    meta: {
      title: 'Service Center Create',
      requiresAuth: true,
      authorizedPermissions: ['ServiceCenters-Locations-Create'],
    },
  },
  {
    path: '/serviceCenterLocation/:id/edit',
    name: 'ServiceCenterLocation-Edit',
    props: true,
    component: ServiceCenterLocationEdit,
    meta: {
      title: 'Service Center Edit',
      requiresAuth: true,
      authorizedPermissions: ['ServiceCenters-Locations-Edit'],
    },
  },
  {
    path: '/viewServiceCenterLocations',
    name: 'ViewServiceCenterLocations',
    component: ViewServiceCenterLocations,
    meta: {
      title: 'Service Center Locations',
      requiresAuth: true,
      authorizedPermissions: ['ServiceCenters-Locations-View'],
    },
  },
  {
    path: '/uploadServiceCenterLocations',
    name: 'ServiceCenterLocations-Upload',
    component: ServiceCenterLocationUpload,
    meta: {
      title: 'Service Center Upload',
      requiresAuth: true,
      authorizedPermissions: ['ServiceCenters-Locations-Upload'],
    },
  },
  {
    path: '/legacyClaimIndex',
    name: 'LegacyClaim-Index',
    props: true,
    component: LegacyClaimIndex,
    meta: {
      title: 'Legacy Claim Index',
      requiresAuth: true,
      authorizedPermissions: ['Operations-FulfillmentSearch-View'],
    },
  },
  {
    path: '/serviceNetworks',
    name: 'ServiceNetworks-Index',
    component: ServiceNetworkIndex,
    meta: {
      title: 'Service Networks Index',
      requiresAuth: true,
      authorizedPermissions: ['ServiceCenters-ServiceNetworks-View'],
    },
  },
  {
    path: '/serviceNetwork/:id/edit',
    name: 'ServiceNetworks-Edit',
    props: true,
    component: ServiceNetworkEdit,
    meta: {
      title: 'Service Networks Edit',
      requiresAuth: true,
      authorizedPermissions: ['ServiceCenters-ServiceNetworks-Edit'],
    },
  },
  {
    path: '/serviceNetworks/create',
    name: 'ServiceNetworks-Create',
    component: ServiceNetworkCreate,
    meta: {
      title: 'Service Networks Create',
      requiresAuth: true,
      authorizedPermissions: ['ServiceCenters-ServiceNetworks-Create'],
    },
  },
  {
    path: '/serviceManufacturers',
    name: 'ServiceManufacturers-Index',
    component: ServiceManufacturersIndex,
    meta: {
      title: 'Service Manufacturers Index',
      requiresAuth: true,
      authorizedPermissions: ['ServiceCenters-Manufacturers-View'],
    },
  },
  {
    path: '/serviceManufacturers/:id/edit',
    name: 'ServiceManufacturers-Edit',
    props: true,
    component: ServiceManufacturersEdit,
    meta: {
      title: 'Service Manufacturers Edit',
      requiresAuth: true,
      authorizedPermissions: ['ServiceCenters-Manufacturers-Edit'],
    },
  },
  {
    path: '/serviceManufacturers/create',
    name: 'ServiceManufacturers-Create',
    component: ServiceManufacturersCreate,
    meta: {
      title: 'Service Manufacturers Create',
      requiresAuth: true,
      authorizedPermissions: ['ServiceCenters-Manufacturers-Create'],
    },
  },
  {
    path: '/uploadServiceManufacturers',
    name: 'ServiceManufacturers-Upload',
    component: ServiceManufacturersUpload,
    meta: {
      title: 'ServiceManufacturer Upload',
      requiresAuth: true,
      authorizedPermissions: ['ServiceCenters-Manufacturers-Upload'],
    },
  },

  {
    path: '/authorization',
    props: true,
    component: FulfillmentAuthorizationParent,
    meta: {
      requiresAuth: true,
      authorizedPermissions: ['Operations-FulfillmentAuthorization-View'],
    },
    beforeEnter: async (to: any, from: any, next: any) => {
      await store.dispatch(INIT_CONFIG);
      return next();
    },
    children: [
      {
        path: ':authorizationId',
        name: 'Authorization',
        props: true,
        component: FulfillmentAuthorizationDetail,
      },
      {
        path: ':authorizationId/tickets/:fulfillmentTicketId',
        name: 'FulfillmentTicket-Detail',
        props: true,
        meta: {
          title: 'Fulfillment Ticket Detail',
          authorizedPermissions: ['Operations-FulfillmentTicket-View'],
        },
        component: FulfillmentTicketDetail,
      },
      {
        path: ':authorizationId/offersets/:offerSetId',
        name: 'OfferSet-Detail',
        props: true,
        meta: {
          title: 'OfferSet Detail',
          authorizedPermissions: ['Operations-FulfillmentAuthorization-View'],
        },
        component: OfferSetDetail,
      },
      {
        path: ':authorizationId/tickets/:fulfillmentTicketId/order/:orderId',
        name: 'Order-Detail',
        props: true,
        meta: {
          title: 'Order Detail',
          authorizedPermissions: ['Operations-FulfillmentTicket-View'],
        },
        component: OrderDetail,
      },
      {
        path: 'tickets/:fulfillmentTicketId',
        name: 'FulfillmentTicket',
        props: true,
        meta: {
          title: 'Fulfillment Ticket Detail',
          authorizedPermissions: ['Operations-FulfillmentTicket-View'],
        },
        beforeEnter: async (to: any, from: any, next: any) => {
          const auth = await authorizationApiProvider.getAuthorizationByTicketId(to.params.fulfillmentTicketId);
          if (auth) {
            return next(`/authorization/${auth.id}/tickets/${to.params.fulfillmentTicketId}`);
          }
        },
      },
    ],
  },
  ...InventoryStatesRoutes,
  ...ConfigRoutes,
  ...VendorRoutes,
  {
    path: '*',
    beforeEnter: (to: any, from: any, next: any) => {
      next('/');
    },
  },
] as RouteConfig[];

const router = new Router({
  mode: 'history',
  routes,
});
router.beforeEach(Vue.prototype.$auth.authRedirectGuard());

router.beforeEach(async (to: any, from: any, next: any) => {
  if (to.path !== '/implicit/callback') {
    await store.dispatch(INIT_USER).catch((error: any) => {});
  }

  if (to.meta.authorizedPermissions?.length) {
    const df = (store.state as any).dynamicfulfillmentmodule as DynamicFulfillmentState;
    const authorizedPermissions = to.meta.authorizedPermissions;
    
    if (!userAuthorizationProviderFactory(df).hasPermission(authorizedPermissions) || (!!to.meta.exclusion && to.meta.exclusion(to.params.id))) {
      const userAccessToken = await Vue.prototype.$auth.getAccessToken();
      if (userAuthorizationProviderFactory(df).hasDefaultPermissions(userAccessToken)) {
        return next('/unauthorized');
      }

      if (to.name === 'Home' && df.userRolesPermissions?.permissions.length > 0) {
        return next('/welcome');
      }

      return next('/denied');
    }
  }

  return next();
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    const title = to.meta && to.meta.title ? to.meta.title : to.name;
    document.title = `${title} - ${regionEnvironment}`;
  });
});

export default router;
