
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { FETCH_ROLES, SEND_ACCESS_REQUEST } from '@/store/dynamicfulfillment/constants';
import { RoleConfig, AccessRequest } from '@/store/dynamicfulfillment/types';
const namespace: string = 'dynamicfulfillmentmodule';

@Component
export default class Unauthorized extends Vue {
  @Action(FETCH_ROLES, { namespace }) fetchRoles: () => RoleConfig[];
  @Action(SEND_ACCESS_REQUEST, { namespace }) sendAccessRequest: any;
  authUser: any = null;
  roles: RoleConfig[] = null;
  fields: any[] = ['requestedRole(s)', 'description'];
  excludedRoles: string[] = ['Admin'];
  accessRequest: AccessRequest = {
    roles: [],
    reason: '',
    region: process.env.VUE_APP_REGION,
    environment: process.env.VUE_APP_ENVIRONMENT,
  };

  async created() {
    this.authUser = await this.$auth.getUser();
    this.roles = await this.fetchRoles();
  }

  get allowedRoles(): { id: string; description: string }[] {
    if (!this.roles) return [];
    return this.roles.filter((role) => !this.excludedRoles.includes(role.id)).map((role) => ({ id: role.id, description: role.description }));
  }

  requestAccess() {
    this.$bvModal.show('bv-accessRequest-modal');
  }

  async submit() {
    await this.sendAccessRequest(this.accessRequest).then(() => {
      this.accessRequest = {
          roles: [],
          reason: '',
          region: process.env.VUE_APP_REGION,
          environment: process.env.VUE_APP_ENVIRONMENT,
        }
    })
    this.$bvModal.hide('bv-accessRequest-modal');
  }

  cancel() {
    this.$bvModal.hide('bv-accessRequest-modal');
  }
}
