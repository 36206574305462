
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { FETCH_ROLES, DELETE_ROLE } from '@/store/dynamicfulfillment/constants';
import { ConfigType, RoutingAction } from '@/store/dynamicfulfillment/types';
import RolesConfigModal from '@/components/configuration/RolesConfigModal.vue'
import { FETCH_CONFIG_HISTORY, UPDATE_ROLE } from '@/store/constants';

@Component({ components: { ConfigIndexComponent, RolesConfigModal } })
export default class RolesIndex extends Vue {  
  configType: ConfigType = ConfigType.Roles;
  fields: any[] = [
    {
      key: 'id',
      sortable: true,
      filterable: true,
    },
    {
      key: 'permissionCount',
      label: 'Permission Count',
    },
    {
      key: 'description',
      label: 'Description',
    },
  ];

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'Roles-Edit', requiredPermission: 'Configuration-Roles-Edit', exclusion: (id: string) => id === 'Admin' },
      { name: RoutingAction.Create, route: 'Roles-Create', requiredPermission: 'Configuration-Roles-Create' },
      { name: RoutingAction.Fetch, action: 'dynamicfulfillmentmodule/' + FETCH_ROLES, requiredPermission: 'Configuration-Roles-View' },
      { name: RoutingAction.Remove, action: 'dynamicfulfillmentmodule/' + DELETE_ROLE, requiredPermission: 'Configuration-Roles-Delete', exclusion: (id: string) => id === 'Admin' },
      { name: RoutingAction.History, action: FETCH_CONFIG_HISTORY, requiredPermission: 'Configuration-Roles-View', exclusion: (id: string) => id === 'Admin' },
      { name: RoutingAction.Revert, action: UPDATE_ROLE, requiredPermission: 'Configuration-Roles-Edit' },
    ];
  }
}
